<!--/*
 * @file           journal
 * @description    理事成员
 * @author         zrh
 * @date           2024-01-10
 */ -->
<template>
  <div class="container">
    <img :src="commingPic" alt="" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      commingPic: require("../assets/images/comming.png"),
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
@media (min-width: 768px) {
  // 768px以上
  .container {
    overflow: hidden;
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    img {
      width: 25vw;
      height: auto;
    }
  }
}
@media (max-width: 767px) {
}
</style>
