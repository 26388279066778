import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import LatestMessage from '../views/LatestMessage.vue';
import EditorialBoard from '../views/EditorialBoard.vue';
import Journal from '../views/Journal.vue';
import Book from '../views/Book.vue';
import MessageDetail from '../views/MessageDetail.vue';
import Article from '../views/Article.vue';
import AnnualMeeting from '../views/AnnualMeeting.vue';
import Lectures from '../views/Lectures.vue';
import SearchDetail from '../views/SearchDetail.vue';
import MemberLogin from '../views/MemberLogin.vue';
import AuthorLogin from '../views/AuthorLogin.vue';
import ForgetPassword from '../views/ForgetPassword.vue';
import UserCenter from '../views/UserCenter.vue';
import Register from '../views/Register.vue';
import IntroductionToTheSociety from '../views/IntroductionToTheSociety.vue';
import ContactSupport from '../views/ContactSupportMould.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/latestMessage',
    name: 'latestMessage',
    component: LatestMessage,
  },
  
  {
    path: '/contactSupport',
    name: 'ContactSupport',
    component: ContactSupport,
  },
  {
    path: '/introductionToTheSociety',
    name: 'IntroductionToTheSociety',
    component: IntroductionToTheSociety,
  },
  {
    path: '/editorialBoard',
    name: 'editorialBoard',
    component: EditorialBoard,
  },
  // 期刊journal
  {
    path: '/journal',
    name: 'journal',
    component: Journal,
  },
  {
    path: '/book',
    name: 'book',
    component: Book,
  },
  {
    path: '/messageDetail',
    name: 'messageDetail',
    component: MessageDetail,
  },
  {
    path: '/article',
    name: 'article',
    component: Article,
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: AnnualMeeting,
  },
  {
    path: '/lecture',
    name: 'lecture',
    component: Lectures,
  },
  {
    path: '/searchDetail',
    name: 'searchDetail',
    component: SearchDetail,
  },
  {
    path: '/memberLogin',
    name: 'MemberLogin',
    component: MemberLogin,
  },
  {
    path: '/authorLogin',
    name: 'AuthorLogin',
    component: AuthorLogin,
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: ForgetPassword,
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: UserCenter,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
