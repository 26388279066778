<!--投稿中心 -- 附件上传组件 -->
<template>
  <div class="containerForm">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="330px"
      class="demo-ruleForm"
    >
      <el-form-item label="稿件标题" prop="title">
        <el-input
          v-model="ruleForm.title"
          style="width: 750px"
          class="inputStyle"
          maxlength="50"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="摘要" prop="summary">
        <el-input
          class="textareaStyle"
          type="textarea"
          v-model="ruleForm.summary"
          style="width: 750px"
          maxlength="300"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-form-item label="稿件类型" prop="type" class="articleType">
        <el-select v-model="ruleForm.type" placeholder="请选择稿件类型">
          <el-option label="原创" value="1"></el-option>
          <el-option label="摘译" value="2"></el-option>
          <el-option label="书评" value="3"></el-option>
          <el-option label="随笔" value="4"></el-option>
          <el-option label="其他" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="受邀来稿" prop="submitType">
        <el-radio-group v-model="ruleForm.submitType">
          <el-radio label="2">是</el-radio>
          <el-radio label="1">否</el-radio>
        </el-radio-group>
        <div v-show="ruleForm.submitType == '2'">
          <el-input
            placeholder="请填写邀稿人"
            style="width: 130px"
            v-model="ruleForm.inviter"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="版权声明" prop="copyright">
        <el-checkbox-group v-model="ruleForm.copyright">
          <el-checkbox
            :label="'我确认已查看、阅读并理解关于版权和作者的指南'"
            name="type"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="伦理声明" prop="ethics">
        <el-checkbox-group v-model="ruleForm.ethics">
          <el-checkbox
            label="我已确认所提供内容符合相关伦理准则以及法律法规"
            name="type2"
          ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item
        label="此稿件是否在任何平台发表过"
        style="margin-left: 196px"
        prop="published"
      >
        <el-radio-group v-model="ruleForm.published">
          <el-radio label="1">是</el-radio>
          <el-radio label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="稿件上传" prop="upload">
        <br />
        <div
          class="descWord"
          style="margin-left: -120px; font-size: 18px; color: #afafaf"
        >
          为什么需要两份稿件?
          本刊实行匿名同行评审,在此过程中,不会向同行评审员透露您的身份
          因此,我们需要一份不包含任何可识别您或您的合著者身份信息的稿件。同时,为了在稿件被录用后尽快发表,我们还需要一个包含投稿人详细信息的版本。文件格式需为pdf。
          请按照要求分别上传两个版本的文件。
        </div>
        <div class="uploadBoxs">
          <div class="uploadBox">
            <div class="title">匿名版本</div>
            <el-upload
              :file-list="fileList2"
              class="upload-demo"
              action="#"
              :http-request="handleFileUploadAnonymous"
              :on-remove="handleRemove"
            >
              <el-button size="small" type="primary" class="uploadBtn"
                >点击上传</el-button
              >
            </el-upload>
          </div>
          <div class="uploadBox">
            <div class="title">附有作者简介版本</div>
            <el-upload
              :file-list="fileList1"
              class="upload-demo"
              action="#"
              :http-request="handleFileUpload"
              :on-remove="handleRemove"
            >
              <el-button size="small" type="primary" class="uploadBtn"
                >点击上传</el-button
              >
            </el-upload>
          </div>
        </div>
      </el-form-item>

      <el-form-item>
        <div
          class="nextStepButton"
          size="medium"
          @click="submitForm('ruleForm')"
        >
          确认提交
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      fileList2: [], //匿名的fileList 不用于上传 仅用于当
      fileList1: [],
      haveAddAnonymousFile: false, //判断是否已经匿名上传 如果已经上传则给予提示
      haveAddFile: false, //判断是否已经非匿名上传 如果已经上传则给予提示
      ruleForm: {
        title: "",
        type: "",
        summary: "",
        submitType: "",
        copyright: false,
        ethics: false,
        published: "", //其他平台发表过的（1发表过，2未发表过）
        anonymousFile: "", //匿名稿件
        namedFile: "", //实名稿件
        inviter: "", //受邀人 TODO:后续会改
      },
      rules: {
        title: [{ required: true, message: "请输入稿件标题", trigger: "blur" }],
        // copyRight 和 ethics 作为单选框 仅作为一种必选项的显示符号
        copyright: [{ required: true, message: "", trigger: "change" }],
        ethics: [{ required: true, message: "", trigger: "change" }],
        published: [{ required: true, message: "", trigger: "change" }],
        type: [
          {
            required: true,
            message: "请至少选择一个稿件类型",
            trigger: "change",
          },
        ],
        upload: [
          { required: false, message: "请选择活动资源", trigger: "change" },
        ],
        summary: [
          { required: true, message: "请填写活动形式", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 含有作者简介版本
    async handleFileUpload(params) {
      //文件类型不符 则取消上传
      let file = params.file;
      if (!this.handleFileType(file)) {
        this.fileList1 = [];
        return;
      }
      // console.log("要退出", this.handleFileType(file));
      const { data: res } = await this.$http.post(
        "/busManuscript/uploadFile",
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.code == "SUCCESS") {
        this.haveAddFile = true; // 表示已经成功上传
        this.ruleForm.namedFile = res.data.fileName;
      } else {
        this.$message.warning(res.message);
      }
    },

    handleFileType(file) {
      const fileExtension = file.name.split(".").pop().toLowerCase(); // 转换为小写
      if (fileExtension === "pdf" || fileExtension === "docx") {
        return true; // 有效文件类型
      } else {
        this.$message.warning("只允许上传docx或pdf类型文件");
        return false; // 无效文件类型
      }
    },
    // 匿名上传
    async handleFileUploadAnonymous(params) {
      let file = params.file;
      if (!this.handleFileType(file)) {
        this.fileList2 = [];
        return;
      }
      const { data: res } = await this.$http.post(
        "/busManuscript/uploadFile",
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.code == "SUCCESS") {
        this.haveAddAnonymousFile = true; // 表示已经成功上传
        this.ruleForm.anonymousFile = res.data.fileName;
        console.log("upload--res", res);
      } else {
        this.$message.warning(res.message);
      }
    },
    // 处理用户删除图片的事件
    handleRemove() {},
    submitForm(formName) {
      console.log("form", this.ruleForm.upload);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.copyright && this.ruleForm.ethics) {
            //已经勾选必填项
            const { data: res } = await this.$http.post(
              "/busManuscript/add",
              this.ruleForm
            );
            console.log("res-submit", res);
            if (res.code == "SUCCESS") {
              this.$message.success("提交成功");
              this.resetForm(formName);
              this.$emit("submit");
            } else {
              this.$message.error(res.message);
            }
          }
        } else {
          this.$message.warning("必要项未填写");
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.containerForm {
  width: 80%;
  margin: 0 auto;
}
.uploadBoxs {
  margin-left: -120px;
  width: 600px;
  margin-top: 20px;
  height: 160px;
  display: flex;
  justify-content: space-between;
  .uploadBox {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 160px;
    border: 1px solid #909090;

    .title {
      margin-top: 20px;
      width: 100%;
      font-size: 20px;
      text-align: center;
    }
  }
}
/deep/ .inputStyle .el-input__suffix {
  left: 700px !important;
}
.textareaStyle {
  resize: none; /* 禁用textarea的拖拽功能 */
  padding-right: 70px;
}
.inputStyle {
  padding-right: 120px;
}
.articleType /deep/ .el-input {
  width: 400px !important;
}
/deep/ .el-form-item__label {
  font-size: 22px;
}
/deep/ .el-input .el-input__count {
  font-size: 18px;
}
/deep/ .el-input .el-input__count {
  font-size: 18px;
}
/deep/ .el-textarea .el-input__count {
  font-size: 18px;
}
/deep/ .el-checkbox__label {
  font-size: 18px;
}
/deep/ .el-radio__label {
  font-size: 18px;
}
.nextStepButton {
  text-align: center;
  background-color: #821b29;
  color: #fff;
  height: 45px;
  width: 200px;
  margin-left: 90px;
  text-align: center;
  line-height: 45px;
  font-size: 20px;
  border-radius: 15px;
  cursor: pointer;
}
.uploadBtn {
  margin-top: 20px;
  width: 160px;
  height: 40px;
  font-size: 20px;
  color: #5e5e5e;
  background: #c7c7c7;
  border: none;
  margin-left: 25%;
}
</style>
