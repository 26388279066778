<template>
  <div class="container">
    <div class="titlePart">
      <div class="title">秉烛丛书</div>
      <div class="line"></div>
    </div>
    <div class="tabPart">
      <el-tabs v-model="tagKeywords" @tab-click="handleClick">
        <!-- <el-tab-pane label="公共神学" name="公共神学">
          <div
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane> -->
        <el-tab-pane label="秉烛丛书" name="秉烛丛书">
          <div
            v-if="page.tagKeywords == '秉烛丛书'"
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="推荐阅读"
          name="推荐阅读"
          style="display: flex; flex-wrap: wrap"
        >
          <div
            v-if="page.tagKeywords == '推荐阅读'"
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="教会事工" name="教会事工">
          <div
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="神学研究" name="神学研究">
          <div
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="圣经系列" name="圣经系列">
          <div
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="其他" name="其他">
          <div
            class="book"
            v-for="(item, index) in bookList"
            :key="index"
            @click="pushIntoDetail(item.id)"
          >
            <div class="bookPic">
              <img :src="item.imgSrc" alt="" />
            </div>
            <div class="bookTitle">{{ item.name }}</div>
            <div class="bookAuthor">{{ item.author }}</div>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="totalCount"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalCount: 0,
      bookList: [],
      tagKeywords: "秉烛丛书",
      // 分页管理器
      page: {
        pageIndex: 1,
        pageSize: 10,
        tagKeywords: "秉烛丛书",
      },
    };
  },
  mounted() {
    this.getBookList();
  },
  methods: {
    pushIntoDetail(id) {
      this.$router.push({
        path: "/messageDetail",
        query: { id, type: "book" },
      });
    },
    // 当用户点击下一页的时候触发
    changePage(val) {
      this.page.pageIndex = val;
      this.getBookList();
    },
    // 当用户点击Tabs 发生变化 触发
    handleClick(tab, event) {
      // console.log(tab.name);
      // if (tab.name != "all") {
      this.page.tagKeywords = tab.name;
      // } else {
      // delete this.page.tagKeywords;
      // }
      // 修改参数 重新请求
      this.getBookList();
    },
    async getBookList() {
      this.bookList = [];
      const { data: res } = await this.$http.get("/busBook/list", this.page);
      let newData = res.data.list;
      this.totalCount = res.data.totalCount;
      console.log("total", this.totalCount);
      newData.forEach((item, index) => {
        const frontendIP = window.location.hostname;
        // 获取前端的端口地址
        let frontendPort = window.location.port;
        if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
        item.imgSrc =
          "http://" +
          frontendIP +
          ":" +
          frontendPort +
          process.env.VUE_APP_BASE_API +
          item.coverImage;
        this.bookList.push(item);
      });
      console.log("Book", this.bookList);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-pager li.active {
  background-color: #484e66 !important;
}
@media (min-width: 768px) {
  // PC端样式
  .container {
    margin: 20px;
    padding: 40px 10vw 0 10vw;
    /deep/.el-tabs__content {
      display: flex;
    }
    .titlePart {
      display: flex;
      align-items: center;
      .title {
        // width: 200px;
        margin-right: 20px;
        font-weight: 600;
        font-size: 36px;
        color: #000;
      }
      .line {
        width: calc(100% - 180px);
        height: 2px;
        background-color: #d2d2d2;
      }
    }
    .book {
      width: auto;
      height: 180px;
      text-align: left;
      height: auto;
      margin: 10px 30px 10px 0;
      cursor: pointer;
      .bookPic {
        img {
          width: 100%;
          height: 100%;
        }
        height: 240px;
        background-color: #f5f5f5;
      }
      .bookTitle {
        font-weight: 600;
        font-size: 18px;
        margin-top: 10px;
        width: 100%;
        height: 30px;
        color: #588eb9;
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        white-space: nowrap; /* 不换行 */
      }
      .bookAuthor {
        margin-top: 2px;
        color: #656565;
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        white-space: nowrap; /* 不换行 */
      }
    }
  }
}
@media (max-width: 767px) {
  // 手机端样式
  .container {
    // margin: 20px;
    padding: 0 5vw;
    // padding: 40px 10vw 0 10vw;
    .titlePart {
      margin-top: 3vh;
      display: flex;
      align-items: center;
      .title {
        width: 30vw;
        margin-right: 20px;
        font-weight: 600;
        font-size: 28px;
        color: #000;
      }
      .line {
        width: calc(100% - 35vw);
        height: 2px;
        background-color: #d2d2d2;
      }
    }
    .book {
      // width: 160px;
      // height: 240px;
      width: 39vw;
      text-align: left;
      margin: 10px 12vw 10px 0;
      cursor: pointer;
      .bookPic {
        img {
          width: 100%;
          height: 100%;
        }
        height: 240px;
        background-color: #f5f5f5;
      }
      .bookTitle {
        font-weight: 600;
        font-size: 18px;
        margin-top: 10px;
        width: 100%;
        height: 30px;
        color: #588eb9;
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        white-space: nowrap; /* 不换行 */
      }
      .bookAuthor {
        margin-top: 2px;
        color: #656565;
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        white-space: nowrap; /* 不换行 */
      }
    }
    .el-tab-pane {
      flex-wrap: wrap;
    }
  }
  .book:nth-child(even) {
    margin-right: 0;
  }
}
</style>
