<template>
  <div class="container">
    <div class="header">
      <div class="headerTitle">最新消息</div>
      <!-- 分割线 -->
      <div class="spliceLine"></div>
    </div>
    <!-- 第一个模块左图右Card型 -->
    <div class="firstPart" @click="pushIntoDetail(firstLatest.id)">
      <div class="leftPart">
        <img :src="firstLatest.imgSrc" alt="" />
      </div>
      <div class="rightPart">
        <div class="timeAndAuthorPart">
          <div class="time" style="color: #c2c2c2">
            <i class="el-icon-date"></i>
            <span style="margin-left: 20px" class="reset">{{
              firstLatest.createTime
            }}</span>
          </div>
          <div class="author" style="color: #c2c2c2">
            <i class="el-icon-user-solid"></i>
            <span style="margin-left: 20px" class="reset">{{
              firstLatest.author
            }}</span>
          </div>
        </div>
        <div class="title">{{ firstLatest.name }}</div>
        <div class="secondTitle">{{ firstLatest.summary }}</div>
        <div class="messageTypes">
          <div
            class="messageType"
            v-for="(keyword, index) in firstLatestKeywordList"
            :key="index"
            @click="pushIntoSearchDetail(keyword)"
          >
            {{ keyword }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="secondPart"
      v-for="(item, index) in messageLatest"
      :key="index"
      @click="pushIntoDetail(item.id)"
    >
      <div class="leftPart">
        <div class="pink">
          <img :src="item.imgSrc" alt="" />
        </div>
      </div>
      <div class="rightPart">
        <div class="timeAndAuthorPart">
          <div class="time" style="color: #c2c2c2">
            <i class="el-icon-date"></i>
            <span style="margin-left: 20px" class="reset">{{
              item.createTime
            }}</span>
          </div>
          <div class="author" style="color: #c2c2c2">
            <i class="el-icon-user-solid"></i>
            <span style="margin-left: 20px" class="reset">{{
              item.author
            }}</span>
          </div>
        </div>
        <div class="title">{{ item.name }}</div>
        <div class="content">{{ item.summary }}</div>
        <div class="messageTypes">
          <div
            class="messageType"
            v-for="(keyword, index) in item.latestKeywordList"
            :key="index"
            @click="pushIntoSearchDetail(keyword)"
          >
            {{ keyword }}
          </div>
        </div>
      </div>
    </div>
    <el-pagination
      style="margin: 20px 0"
      background
      layout="prev, pager, next"
      :total="totalCount"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  data() {
    return {
      totalCount: 0,
      pageIndex: 1,
      messageLatest: [],
      firstLatest: {},
      firstLatestKeywordList: [],
    };
  },
  mounted() {
    this.getLatestMessage();
  },
  methods: {
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.getLatestMessage();
    },
    // 点击按钮进入搜索界面
    pushIntoSearchDetail(keyword) {
      this.$router.push({
        path: "/searchDetail",
        query: { keyword, mode: "and" },
      });
    },
    pushIntoDetail(id) {
      this.$router.push({
        path: "/messageDetail",
        query: { id, type: "message" },
      });
    },
    async getLatestMessage() {
      this.messageLatest = [];
      const { data: res } = await this.$http.get("/busNews/list", {
        pageIndex: this.pageIndex,
        pageSize: 10,
      });
      let newData = res.data.list;
      this.totalCount = res.data.totalCount;
      console.log("total", this.totalCount);
      newData.forEach((item, index) => {
        if (index == 0) {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          this.firstLatest = item;
          // 将其分割成数组 作为记录
          if (this.firstLatest.tagKeywords)
            this.firstLatestKeywordList =
              this.firstLatest.tagKeywords.split(" ");
        } else {
          const frontendIP = window.location.hostname;
          // 获取前端的端口地址
          let frontendPort = window.location.port;
          if (frontendPort == "") frontendPort = 80; // 默认为空则将其设置为80端口
          item.imgSrc =
            "http://" +
            frontendIP +
            ":" +
            frontendPort +
            process.env.VUE_APP_BASE_API +
            item.coverImage;
          if (item.tagKeywords)
            item.latestKeywordList = item.tagKeywords.split(" ");
          this.messageLatest.push(item);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-pager li.active {
  background-color: #484e66 !important;
}
@media (min-width: 768px) {
  // PC端
  .container {
    padding: 40px 10vw 0 10vw;
    background-color: #fafafa;
    .header {
      font-weight: 600;
      font-size: 30px;
      text-align: left;
      display: flex;
      // justify-content: center;
      align-items: center;
      color: #030303;
      .headerTitle {
        width: 200px;
      }
      .spliceLine {
        height: 2px;
        width: calc(100vw - 100px);
        background-color: #cdcdcd;
      }
    }
    .firstPart {
      display: flex;
      margin-top: 20px;
      height: 400px;
      width: 100%;
      cursor: pointer;
      .leftPart {
        width: 500px;
        height: 400px;
        background-color: #f5f5f5;
        img {
          width: 500px;
          max-width: 500px;
          min-width: 500px;
          height: 400px;
        }
      }
      .rightPart {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 60%;
        height: 400px;
        padding-left: 30px;
        .timeAndAuthorPart {
          display: flex;
          margin: 50px 30px;
          .time {
            margin-right: 30px;
          }
        }
        .title {
          margin: 0px 30px;
          text-align: left;
          font-weight: 600;
          font-size: 30px;
        }
        .secondTitle {
          width: 80%;
          height: 100px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin: 20px 30px;
          font-size: 30px;
        }
        .content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin: 0 30px;
          height: 300px;
        }
        .messageTypes {
          display: flex;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: left;
          margin: 20px 30px;
          font-weight: 650;
          color: #3f387f;
          font-size: 20px;
          .messageType {
            margin-right: 20px;
            cursor: pointer;
          }
          .messageType:hover {
            color: red;
          }
        }
      }
    }
    .secondPart {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      width: calc(100% - 20px);
      height: 300px;
      background-color: #efefef;
      cursor: pointer;
      .leftPart {
        padding: 30px;
        background-color: #fff;
        height: calc(100% - 80px);
        width: calc(30% - 5vw);
        width: 450px;
        max-width: 450px;
        min-width: 450px;
        min-height: 220px;
        max-height: 220px;
        height: 220px;
        .pink {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .rightPart {
        padding: 30px;
        background-color: #fff;
        height: calc(100% - 80px);
        width: calc(70% - 40px);
        .timeAndAuthorPart {
          display: flex;
          .time {
            margin-right: 30px;
          }
        }
        .title {
          margin: 0px 30px;
          margin-bottom: 20px;
          text-align: left;
          font-weight: 600;
          font-size: 30px;
          max-height: 100px;
          word-break: break-all;
          -webkit-line-clamp: 3; /* 限制行数 */
          -webkit-box-orient: vertical;
          overflow: hidden; /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 显示省略号 */
        }
        .secondTitle {
          text-align: left;
          margin: 20px 30px;
          font-size: 30px;
        }
        .content {
          text-align: left;
          margin: 0 30px;
          padding-right: 30px;
          max-height: 60px;
          overflow: hidden;
          text-overflow: ellipsis; /* 显示省略号 */
          word-break: break-all;
          -webkit-line-clamp: 3; /* 限制行数 */
          -webkit-box-orient: vertical;
          overflow: hidden; /* 隐藏溢出的部分 */
          text-overflow: ellipsis; /* 显示省略号 */
          width: 90%;
        }
        .messageTypes {
          display: flex;
          text-align: left;
          margin: 20px 30px;
          font-weight: 650;
          color: #3f387f;
          font-size: 20px;
          .messageType {
            margin-right: 20px;
            cursor: pointer;
          }
          .messageType:hover {
            color: red;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .reset {
    margin-left: 0 !important;
    margin-bottom: 10px;
  }
  .container {
    padding: 40px 5vw 0 5vw;
    // background-color: #fafafa;
    .header {
      font-weight: 600;
      font-size: 30px;
      text-align: left;
      display: flex;
      // justify-content: center;
      align-items: center;
      color: #030303;
      .headerTitle {
        width: 200px;
      }
      .spliceLine {
        height: 2px;
        width: calc(100vw - 100px);
        background-color: #cdcdcd;
      }
    }
    .firstPart {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      width: 78vw;
      height: auto;
      box-shadow: 5px 5px 5px 0px rgba(245, 245, 245, 0.8),
        -5px -5px 5px 0px rgba(245, 245, 245, 0.8),
        5px -5px 5px 0px rgba(245, 245, 245, 0.8),
        -5px 5px 5px 0px rgba(245, 245, 245, 0.8);
      .leftPart {
        //width: 30vw;
        padding: 30px;
        height: auto;
        // background-color: #f5f5f5;
        img {
          width: 100%;
          height: auto;
        }
      }
      .rightPart {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        height: auto;
        .timeAndAuthorPart {
          // display: flex;
          margin: 3vh 0;
          text-align: left;
          padding: 0 10vw;
          font-size: 16px;
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .time {
            margin-right: 30px;
          }
        }
        .title {
          margin: 0px 30px;
          text-align: left;
          font-weight: 600;
          font-size: 26px;
          width: 80%;
          // padding-left: 4vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .secondTitle {
          width: 80%;
          height: 4vh;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin: 20px 30px;
          font-size: 20px;
        }
        .content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          margin: 0 30px;
          height: 300px;
        }
        .messageTypes {
          display: flex;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: left;
          margin: 20px 30px;
          font-weight: 650;
          color: #3f387f;
          font-size: 20px;
          .messageType {
            width: 100%;
            text-align: left;
            margin-left: 10vw;
            cursor: pointer;
          }
          .messageType:hover {
            color: red;
          }
        }
      }
    }
    .secondPart {
      margin-top: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      width: 78vw;
      height: auto;
      // background-color: #efefef;
      cursor: pointer;
      box-shadow: 5px 5px 5px 0px rgba(245, 245, 245, 0.8),
        -5px -5px 5px 0px rgba(245, 245, 245, 0.8),
        5px -5px 5px 0px rgba(245, 245, 245, 0.8),
        -5px 5px 5px 0px rgba(245, 245, 245, 0.8);
      .leftPart {
        padding: 30px;
        background-color: #fff;
        height: calc(100% - 80px);
        width: calc(83vw - 80px);
        .pink {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .rightPart {
        background-color: #fff;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        .timeAndAuthorPart {
          padding: 0 10vw;
          width: 80%;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          .time {
            margin-right: 20px;
          }
        }
        .title {
          // margin: 0px 30px;
          text-align: left;
          font-weight: 600;
          padding: 0 20px;
          font-size: 30px;
          margin: 10px;
          width: 80%;
          // padding-left: 4vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .secondTitle {
          text-align: left;
          margin: 20px 30px;
          font-size: 30px;
        }
        .content {
          text-align: left;
          margin: 10px 30px;
          height: 10%;
          word-break: break-all;
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .messageTypes {
          display: flex;
          text-align: left;
          flex-wrap: wrap;
          margin: 20px 30px;
          font-weight: 650;
          color: #3f387f;
          font-size: 20px;
          width: 80%;

          .messageType {
            margin-right: 20px;
            cursor: pointer;
            text-align: left;
            // margin-left: 10vw;
          }
          .messageType:hover {
            color: red;
          }
        }
      }
    }
  }
}
</style>
