<template>
  <div class="container">
    <div class="main">
      <div class="header">
        {{ type == 'member' ? '用户注册' : '作者注册' }}
      </div>
      <el-form
        :model="form"
        :rules="rules"
        ref="form"
        label-width="100px"
        class="ruleForm"
      >
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="邮箱验证码" prop="code" style="text-align: left">
          <el-input v-model="form.code" style="width: 40%"></el-input>
          <el-button
            type="primary"
            @click="sendCode"
            style="margin-left: 20px"
            :disabled="controlButtonSend != 0"
            >发送</el-button
          >
          <span style="margin-left: 20px" v-if="controlButtonSend != 0">{{
            controlButtonSend
          }}</span>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="form.nickName"></el-input>
        </el-form-item>
        <el-form-item
          label="真实姓名"
          :prop="type == 'author' ? 'realName' : ''"
        >
          <el-input v-model="form.realName"></el-input>
        </el-form-item>
        <el-form-item
          label="所在机构"
          :prop="type == 'author' ? 'organization' : ''"
        >
          <el-input v-model="form.organization"></el-input>
        </el-form-item>
        <el-form-item label="性别" :prop="type == 'author' ? 'sex' : ''">
          <el-select v-model="form.sex" placeholder="性别">
            <el-option label="男" value="0"></el-option>
            <el-option label="女" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生日" :prop="type == 'author' ? 'birthday' : ''">
          <el-col :span="11">
            <el-date-picker
              v-model="form.birthday"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="宗教" :prop="type == 'author' ? 'religion' : ''">
          <el-select v-model="form.religion" placeholder="宗教">
            <el-option
              v-for="(item, index) in optionList.religious"
              :label="item"
              :value="item"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="居住地"
          :prop="type == 'author' ? 'residence' : ''"
        >
          <el-select v-model="form.residence" placeholder="中国">
            <el-option
              v-for="(item, index) in optionList.regions"
              :label="item"
              :value="item"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学历" :prop="type == 'author' ? 'education' : ''">
          <el-select v-model="form.education" placeholder="学历">
            <el-option
              v-for="(item, index) in optionList.educations"
              :label="item"
              :value="item"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职业" :prop="type == 'author' ? 'occupation' : ''">
          <el-select v-model="form.occupation" placeholder="职业">
            <el-option
              v-for="(item, index) in optionList.occupations"
              :label="item"
              :value="item"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')"
            >立即创建</el-button
          >
          <el-button @click="cancelRegister()">取消注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    // 自定义邮箱规则
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/;
      if (regEmail.test(value)) {
        // 合法邮箱
        return callback();
      }
      callback(new Error('请输入合法邮箱'));
    };
    return {
      type: '',
      controlButtonSend: 0,
      form: {
        email: '',
        code: '',
        password: '',
        nickName: '',
        sex: '',
        birthday: '',
        religion: '',
        residence: '',
        education: '',
        occupation: '',
      },
      // 校验规则
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请选择活动验证码', trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
        ],
        nickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        sex: [{ required: true, message: '请输入性别', trigger: 'blur' }],
        birthday: [{ required: true, message: '请输入性别', trigger: 'blur' }],
        religion: [{ required: true, message: '请输入性别', trigger: 'blur' }],
        education: [{ required: true, message: '请输入性别', trigger: 'blur' }],
        occupation: [
          { required: true, message: '请输入性别', trigger: 'blur' },
        ],
        residence: [{ required: true, message: '请输入性别', trigger: 'blur' }],
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
        ],
        organization: [
          { required: true, message: '请输入所在机构', trigger: 'blur' },
        ],
      },
      optionList: {
        religious: [],
        regions: [],
        educations: [],
        occupations: [],
      },
    };
  },
  created() {},
  mounted() {
    this.type = this.$route.query.type;
    this.init();
  },
  methods: {
    //初始化下拉框数据
    init() {
      this.getAllReligious();
      this.getAllOccupation();
      this.getAllRegions();
      this.getAllEducations();
    },
    // 获取全部学历
    async getAllEducations() {
      const { data: res } = await this.$http.get('/common/getAllEducations');
      console.log('res', res);
      if (res.code == 'SUCCESS') {
        this.optionList.educations = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部职业
    async getAllOccupation() {
      const { data: res } = await this.$http.get('/common/getAllOccupations');
      console.log('res', res);
      if (res.code == 'SUCCESS') {
        this.optionList.occupations = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部宗教
    async getAllReligious() {
      const { data: res } = await this.$http.get('/common/getAllReligions');
      console.log('res', res);
      if (res.code == 'SUCCESS') {
        this.optionList.religious = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 获取全部地区
    async getAllRegions() {
      const { data: res } = await this.$http.get('/common/getAllRegions');
      console.log('res', res);
      if (res.code == 'SUCCESS') {
        this.optionList.regions = res.data;
      } else {
        this.$message.warning(res.message);
      }
    },
    // 跳转到作者登录
    pushIntoAuthorLogin() {
      this.$router.push({ path: '/authorLogin' });
    },
    // 60秒倒计时
    countControlButtonSend() {
      this.controlButtonSend = 60;
      const timer = setInterval(() => {
        this.controlButtonSend--;

        if (this.controlButtonSend === 0) {
          clearInterval(timer);
          console.log('定时器已关闭');
        }
      }, 1000); // 每秒执行一次
    },
    // 二维码
    async sendCode() {
      if (this.form.email == '') {
        this.$message.warning('请先输入邮箱');
      } else {
        this.countControlButtonSend();
        const { data: res } = await this.$http.get(
          '/captcha/sendEmailCaptcha',
          {
            toEmail: this.form.email,
          }
        );
        console.log('res', res);
      }
    },
    // 临时接口 撤销注册
    async cancelRegister() {
      const { data: res } = await this.$http.get('/temp/deleteUserByEmail', {
        email: '1249241376@qq.com',
      });
      console.log('res', res);
    },
    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.type == 'member') {
            // 用户注册
            const { data: res } = await this.$http.post('/register', {
              ...this.form,
            });
            console.log(res);
            if (res.code == 'SUCCESS') {
              this.$message.success('注册成功');
              this.$router.push('/memberLogin');
            } else {
              this.$message.warning(res.message);
            }
          } else {
            // 作者注册
            const { data: res } = await this.$http.post('/registerAuthor', {
              ...this.form,
            });
            console.log(res);
            if (res.code == 'SUCCESS') {
              this.$message.success('注册成功');
              this.$router.push('/authorLogin');
            } else {
              this.$message.warning(res.message);
            }
          }
        } else {
          this.$message.warning('请完成必填表单项');
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  .main {
    margin-top: 80px;
    // background-color: pink;
    width: 600px;
    height: auto;
    box-shadow: 5px 5px 5px 0px rgba(245, 245, 245, 0.8),
      -5px -5px 5px 0px rgba(245, 245, 245, 0.8),
      5px -5px 5px 0px rgba(245, 245, 245, 0.8),
      -5px 5px 5px 0px rgba(245, 245, 245, 0.8);
  }
  .header {
    margin-bottom: 50px;
    background-color: #15242c;
    width: 100%;
    height: 60px;
    font-size: 30px;
    color: #fff;
    line-height: 60px;
    text-align: center;
  }
  .ruleForm {
    padding: 0 40px;
  }
  /deep/ .el-select {
    display: block;
  }
  /deep/ .el-input__inner {
    width: 100%;
  }
  /deep/ .el-input__inner:nth-child(5) {
    width: 100% !important;
  }
  /deep/ .el-input {
    text-align: left;
  }
}
</style>
