import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from '../src/api/request';
import ElementUI from 'element-ui';
import 'quill/dist/quill.core.css';
import 'element-ui/lib/theme-chalk/index.css';
import message from './utils/message.js';
Vue.prototype.$messages = message;
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
