<!--投稿中心 -->
<template>
  <div class="container">
    <el-table :data="tableData" style="width: 80%; margin: 0 auto">
      <el-table-column prop="title" label="文章标题" width="450">
      </el-table-column>
      <el-table-column prop="approvalStatus" label="稿件状态" width="180">
        <template slot-scope="scope">
          <el-tag
            size="medium"
            v-if="scope.row.approvalStatus === 1"
            type="success"
            >已提交</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 2"
            type="info"
            >初审通过</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 3"
            type="warning"
            >初审未通过</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 4"
            type="danger"
            >复审通过</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 5"
            type="gray"
            >复审未通过</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 6"
            type="gray"
            >同行评审中</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 7"
            type="gray"
            >终审通过</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 8"
            type="gray"
            >终审未通过</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 9"
            type="gray"
            >终审返稿</el-tag
          >
          <el-tag
            size="medium"
            v-else-if="scope.row.approvalStatus === 10"
            type="gray"
            >已采用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="上传时间" width="360">
      </el-table-column>
      <el-table-column label="操作" width="250">
        <template slot-scope="scope">
          <el-upload
            class="upload-demo"
            action="#"
            :http-request="handleFileUpload"
            :show-file-list="false"
            :limit="10"
            v-if="scope.row.approvalStatus === 9"
          >
            <el-button
              @click="changeCurrentId(scope.row)"
              type="primary"
              style="background-color: #82111f; border: none; outline: none"
              >点击上传</el-button
            >
          </el-upload>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import ContributeAricleForm from "./ContributeAricleForm.vue";
export default {
  components: {
    ContributeAricleForm,
  },
  data() {
    return {
      currentControlRow: null,
      currentUplodaUrl: null,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
    };
  },
  created() {},
  mounted() {
    // 获取请求列表
    this.getTableList();
  },
  methods: {
    // 获取用户当前创建的内容
    changeCurrentId(params) {
      this.currentControlRow = params;
    },
    // 文件上传
    async handleFileUpload(params) {
      let file = params.file;
      const { data: res } = await this.$http.post(
        "/busManuscript/uploadFile",
        { file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.code == "SUCCESS") {
        this.currentControlRow.anonymousFile = res.data.fileName;
        this.handleUpdateData();
      } else {
        this.$message.warning(res.message);
      }
    },
    async handleUpdateData() {
      const { data: res } = await this.$http.post(
        "/busManuscript/edit",
        this.currentControlRow
      );
      if (res.code == "SUCCESS") {
        this.$message.success("操作成功");
      } else {
        this.$message.warning(res.message);
      }
    },
    async getTableList() {
      const res = await this.$http.get(
        `/busManuscript/list?pageIndex=${this.pageIndex}&pageSize=${this.pageSize}`
      );
      this.tableData = res.data.data.list;
      console.log("tableData", this.tableData);
    },
  },
};
</script>
<style lang="less" scoped>
.el-table {
  font-size: 22px;
}
/deep/ .el-tag--medium {
  height: 35px;
  line-height: 35px;
  // font-weight: 600;
  font-size: 20px;
}
/deep/ .el-table th.el-table__cell.is-leaf {
  text-align: center;
}
/deep/ .el-table td.el-table__cell div {
  text-align: center;
}
</style>
