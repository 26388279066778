import { Message } from 'element-ui';
export default {
  warning: (msg) => {
    return Message({
      showClose: true,
      message: msg,
      type: 'warning',
      center: true,
      duration: 3000,
    });
  },
  error: (msg) => {
    return Message({
      showClose: true,
      message: msg,
      type: 'error',
      center: true,
      duration: 3000,
    });
  },
  success: (msg) => {
    return Message({
      showClose: true,
      message: msg,
      type: 'success',
      center: true,
      duration: 3000,
    });
  },
  warningClose() {
    return Message.closeAll(); //全局删除Message语句的方法
  },
};
