<template>
  <div class="container">
    <div class="main">
      <div class="header">忘记密码</div>
      <div class="content">
        <el-input
          placeholder="请输入邮箱账号"
          v-model="form.email"
          clearable
          class="username"
        >
        </el-input>
      </div>
      <!-- 验证码 -->
      <div class="code">
        <el-input
          placeholder="验证码"
          v-model="form.code"
          clearable
          class="password"
        >
        </el-input>
        <el-button
          type="primary"
          class="getCode"
          @click="sendCode"
          :disabled="controlButtonSend != 0"
          >发送</el-button
        >
        <span
          class="codeTime"
          style="margin-left: 20px"
          v-if="controlButtonSend != 0"
          >{{ controlButtonSend }}</span
        >
      </div>
      <div class="content">
        <el-input
          placeholder="请输入新密码"
          v-model="form.newPassword"
          clearable
          type="password"
          class="username"
        >
        </el-input>
        <el-input
          placeholder="确认新密码"
          v-model="form.conformPassword"
          clearable
          type="password"
          class="username"
        >
        </el-input>
      </div>
      <div class="btn">
        <el-button type="primary" class="login" @click="submitForgetPassword"
          >提交</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // type 用于重置成功之后的回退回原来页面
      type: '',
      form: {
        email: '',
        conformPassword: '',
        newPassword: '',
        code: '',
      },
      controlButtonSend: 0,
    };
  },
  created() {},
  mounted() {
    console.log('router', this.$route.query.type);
    this.type = this.$route.query.type;
  },
  methods: {
    async submitForgetPassword() {
      if (this.form.conformPassword != this.form.newPassword) {
        this.$message.warning('前后密码不一致');
      } else {
        let params = this.form;
        delete params.conformPassword;
        const { data: res } = await this.$http.post(
          '/forgetAndResetPwd',
          params
        );
        if (res.code == 'SUCCESS') {
          this.$message.success('密码重置成功');
          if (this.type == 'member') {
            this.pushIntoMemberLogin();
          } else {
            this.pushIntoAuthorLogin();
          }
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    async sendCode() {
      if (this.form.email == '') {
        this.$message.warning('请先输入邮箱');
      } else {
        this.countControlButtonSend();
        const { data: res } = await this.$http.get(
          '/captcha/sendEmailCaptcha',
          {
            toEmail: this.form.email,
          }
        );
        console.log('res', res);
      }
    },
    pushIntoAuthorLogin() {
      this.$router.push({ path: '/authorLogin' });
    },
    countControlButtonSend() {
      this.controlButtonSend = 60;
      const timer = setInterval(() => {
        this.controlButtonSend--;

        if (this.controlButtonSend === 0) {
          clearInterval(timer);
          console.log('定时器已关闭');
        }
      }, 1000); // 每秒执行一次
    },
    pushIntoMemberLogin() {
      this.$router.push({ path: '/memberLogin' });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-input__suffix {
  display: none;
}
.container {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  .main {
    margin-top: 80px;
    // background-color: pink;
    width: 600px;
    height: auto;
    box-shadow: 5px 5px 5px 0px rgba(245, 245, 245, 0.8),
      -5px -5px 5px 0px rgba(245, 245, 245, 0.8),
      5px -5px 5px 0px rgba(245, 245, 245, 0.8),
      -5px 5px 5px 0px rgba(245, 245, 245, 0.8);
  }
  .header {
    background-color: #15242c;
    width: 100%;
    height: 60px;
    font-size: 30px;
    color: #fff;
    line-height: 60px;
    text-align: center;
  }
  .content {
    padding: 40px 80px;
    padding-bottom: 0;
    /deep/ .el-input--suffix .el-input__inner {
      height: 55px;
      border-radius: 10px;
      font-size: 20px;
      color: #bcbcbe;
      background-color: #f4f6fa;
    }
    .username {
      height: 75px;
      margin-bottom: 30px;
    }
  }
  .forgetPassword {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 80px;
    margin-top: 20px;
    font-size: 20px;
    color: #bcbcbc;
  }
  .btn {
    text-align: center;
    padding: 0 80px;
    margin-bottom: 30px;
    .getCode {
      font-size: 26px;
    }
    .login {
      width: 180px;
      font-size: 18px;
      margin-right: 70px;
      border-radius: 10px;
      background-color: #15242d;
      border-color: #15242d;
    }
    .register {
      width: 180px;
      font-size: 18px;
      border-radius: 10px;
      background-color: #83111e;
      border-color: #83111e;
    }
  }
  .code {
    display: flex;
    width: 100%;
    padding: 0 80px;
    padding-bottom: 0;
    text-align: left;
    .codeTime {
      display: flex;
      align-items: center;
    }
    /deep/ .el-input--suffix .el-input__inner {
      height: 55px;
      width: 80%;
      border-radius: 10px;
      font-size: 20px;
      color: #bcbcbe;
      background-color: #f4f6fa;
    }
    .el-color-picker__icon,
    .el-input,
    .el-textarea {
      width: 50%;
    }
    .pic {
      background-color: pink;
      width: 20%;
    }
  }
  .authorLogin {
    height: 60px;
    margin-top: 30px;
    background-color: #1c5aa0;
    border-color: #1c5aa0;
    width: 100%;
    font-size: 22px;
    border-radius: 10px;
  }
  .authorLoginOrRegister {
    padding: 0 80px;
    height: 100px;
  }
}
</style>
