<!--投稿中心 -->
<template>
  <div class="container">
    <div class="stepOne" v-if="status == '0'">
      <div class="startToContribute" size="medium" @click="changeStatus('1')">
        点击进行投稿
      </div>
    </div>
    <div class="stepTwo" v-if="status == '1'">
      <div class="textarea">
        <textarea name="" id="" placeholder="" class="textareaContent">
平台投稿详细规则</textarea
        >
      </div>
      <div class="conformCheckbox">
        <el-checkbox v-model="checked" style="zoom: 180%"
          >我已确认投稿相关细则</el-checkbox
        >
      </div>
      <div class="nextStep">
        <div class="nextStepButton" size="medium" @click="changeStatus('2')">
          下一步
        </div>
      </div>
    </div>
    <div class="stepThree" v-if="status == '2'">
      <contributeAricleForm @submit="finishSubmit" />
    </div>
  </div>
</template>
<script>
import ContributeAricleForm from "./ContributeAricleForm.vue";
export default {
  components: {
    ContributeAricleForm,
  },
  data() {
    return {
      status: "0", //0表示还未投稿
      checked: false, //确认用户确认投稿相关细则 是否勾选
    };
  },
  created() {},
  mounted() {},
  methods: {
    finishSubmit() {
      this.status = "0";
      this.$emit("submit");
    },
    changeStatus(status) {
      // 要进入status 为2的层 要进行修改的话
      if (status == "2") {
        // 如果是第一层的 就直接通过 如果是第二层 需要勾选了 确认投稿相关细则之后 才可以进行下一步
        if (!this.checked) {
          this.$message.warning("请先勾选确认熟知按钮");
        } else {
          this.status = status;
        }
      } else {
        this.status = status;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.stepTwo {
  height: 800px;

  .textarea {
    text-align: center;
    width: 800px;
    height: 400px;
    margin: 0 auto;
    .textareaContent {
      font-size: 18px;
      font-weight: 600;
      resize: none; /* 禁用textarea的拖拽功能 */
      border: none;
      outline: none;
      background-color: #f3f3f3;
      color: #818181;
      padding: 30px;
      width: 100%;
      height: 100%;
    }
  }
  .conformCheckbox {
    font-size: 50px;
    margin-top: 100px;
  }
  .nextStep {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .nextStepButton {
      text-align: center;
      background-color: #821b29;
      color: #fff;
      height: 45px;
      width: 200px;
      text-align: center;
      line-height: 45px;
      font-size: 20px;
      border-radius: 15px;
      cursor: pointer;
    }
  }
}

.stepOne {
  text-align: left;
  .startToContribute {
    background-color: #821b29;
    color: #fff;
    height: 45px;
    width: 200px;
    text-align: center;
    line-height: 45px;
    font-size: 20px;
    border-radius: 15px;
    cursor: pointer;
  }
}
</style>
