import { render, staticRenderFns } from "./ContributeArticle.vue?vue&type=template&id=58a0afc5&scoped=true"
import script from "./ContributeArticle.vue?vue&type=script&lang=js"
export * from "./ContributeArticle.vue?vue&type=script&lang=js"
import style0 from "./ContributeArticle.vue?vue&type=style&index=0&id=58a0afc5&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58a0afc5",
  null
  
)

export default component.exports