/**
 * axios二次封装
 */
import axios from 'axios';
import { Loading, Message } from 'element-ui';
// 创建axios 实例对象,添加全局配置
const service = axios.create({
  baseURL: null,
});
// 请求拦截
service.interceptors.request.use((req) => {
  const headers = req.headers;
  console.log("request",process.env.VUE_APP_BASE_API)
  const token = JSON.parse(localStorage.getItem('user'))?.token || '';
  if (token != '') headers.Authorization = token;
  return req;
});
// 响应拦截
service.interceptors.response.use((res) => {
  console.log('interceptors-data', res );
  if (res.config.url == '/captcha/captchaImage') {
    return res;
  } else {
    let loadingInstance = Loading.service();
    loadingInstance.close();
    return res;
  }
});
const request = {};

// init->根据传参初始化修改config的配置(局部mock 和全局mock)并将配置好的service返回
request.init = function (option) {
  // if (option) {
  //   service.headers = option.header;
  //   console.log(service.headers);
  // }
  service.defaults.baseURL = process.env.VUE_APP_BASE_API;
  return service;
};
request.get = function (url, data, option) {
  return request.init(option).get(url, {
    //data数据要给到axios Get请求的 params
    params: data,
    ...option,
  });
};
request.post = function (url, data, option) {
  console.log('option', option);
  return request.init(option).post(url, data, option);
};
request.put = function (url, data, option) {
  return request.init(option).put(url, data, option);
};
request.delete = function (url, data, option) {
  return request.init(option).delete(url, {
    data,
    ...option,
  });
};
request.getByPython = function (url, data, option) {
  return request.init(option).get(url, {
    params: data,
  });
};
export default request;
