<!--/*
 * @file           searchDetail
 * @description    搜索详情列表页
 * @author         zrh
 * @date            2024-01-17
 */ -->
<template>
  <div class="container">
    <div class="header">
      <el-input placeholder="请输入搜索内容" v-model="input" class="searchInput">
        <el-button slot="append" icon="el-icon-search" @click="searchForDetail"></el-button>
      </el-input>
    </div>
    <div class="body">
      <div class="bodyHeader">
        <div class="spliceLine"></div>
        <div class="bodyTitle">搜索结果</div>
        <!-- 分割线 -->
        <div class="spliceLine"></div>
      </div>
      <!-- 搜索结果 -->
      <div class="results">
        <div class="result" v-for="(item, index) in keywordList" :key="index" @click="pushIntoDetail(item)">
          <div class="title">
            <div class="type">{{ item.contentTypeCn }}</div>
            <div class="messageTitle" v-html="item.name"></div>
          </div>
          <div class="time">{{ item.createTime }}</div>
          <div class="abstract" v-html="item.summary"></div>
          <div class="keywords">
            <div class="keyword" v-for="(keyword ,index) in item.tagKeywords" :key="index" @click.stop="pushIntoSearchDetail(keyword)">
              <span :style="{'color': keyword == input ?'red':''}"> {{ keyword }} </span>
            </div>
          </div>
        </div>
      </div>
      <el-pagination style="margin-bottom: 30px" layout="prev, pager, next" :total="keywordList.length" @current-change="handleCurrentChange"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: '', //用户输入搜索框的关键字
      page: {
        pageIndex: 1,
        pageSize: 10,
      },
      // 确定到底是用 Or查询 还是用and查询
      mode: 'or',
      keywordList: [],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  beforeRouteUpdate(to, from, next) {
    console.log('到', to.query.keyword);
    // 判断路由是否不相等，不相等时继续执行
    if (to.name != from.name) {
      next();
    } else if (to.name == from.name && to.name == "searchDetail") {
      this.mode = to.query.mode 
      // 跳转到相同的页面的不同的路径参数 进行列表的刷新
      if (this.mode == 'or') {
        this.page.keyword = to.query.keyword;
      } else {
        this.page.tagKeyword = to.query.keyword;
      }
      this.input = to.query.keyword;
      this.searchForKeyword();
      next();
    }
  },
  methods: {
     // 点击按钮进入搜索界面
  pushIntoSearchDetail(keyword){
    this.$router.push({path:"/searchDetail",query:{ keyword,mode:'and' }})
  },
    init() {
      this.mode = this.$route.query.mode; //直接查询keyword 和name里面的内容 用 or 只查询其中一个用and
      if (this.mode == 'or') {
        this.page.keyword = this.$route.query.keyword;
      } else {
        this.page.tagKeyword = this.$route.query.keyword;
      }
      this.input = this.$route.query.keyword;
      this.searchForKeyword();
    },
    // 点击搜索按钮
    searchForDetail() {
      this.$router.push({
        path: '/searchDetail',
        query: { keyword: this.input, mode: 'or' },
      });
      this.searchForKeyword();
    },
    async searchForKeyword() {
      if (this.mode == 'or') {
        console.log("page",this.page)
        const { data: res } = await this.$http.get(
          '/searchBox/searchWithOr',
          this.page
        );
        this.keywordList = res.data.list;
        this.keywordList.forEach((item) => {
          item.name = this.highlightKeyword(item.name);
          item.summary = this.highlightKeyword(item.summary);
          if(item.tagKeywords){
            item.initTagKeywords = item.tagKeywords
            console.log("item",item.initTagKeywords)
          }
          if(item.tagKeywords){
            item.tagKeywords = item.tagKeywords.split(' ');
          }
          // item.tagKeywords = this.highlightKeyword(item.tagKeywords);
        });
      } else {
        const { data: res } = await this.$http.get(
          '/searchBox/searchWithAnd',
          this.page
        );
        this.keywordList = res.data.list;
        console.log('this', this.page.tagKeyword);
        this.keywordList.forEach((item) => {
          if(item.tagKeywords){
            item.tagKeywords = item.tagKeywords.split(" ")
          }
          // item.tagKeywords = this.highlightKeyword(item.tagKeywords);
        });
      }
    },
    highlightKeyword(content) {
      if (this.mode == 'or') {
        content.replace(
          new RegExp(this.page.tagKeyword, 'g'),
          '<span style="color:red">$&</span>'
        );
        return content.replace(
          new RegExp(this.page.keyword, 'g'),
          '<span style="color:red">$&</span>'
        );
      } else
        return content.replace(
          new RegExp(this.page.tagKeyword, 'g'),
          '<span style="color:red">$&</span>'
        );
      // 将包含keyword的内容渲染成红色
    },
    handleCurrentChange(val) {
      this.page.pageIndex = val;
    },
    pushIntoDetail(item) {
      if (item.contentType == 'MAGAZINE') {
        this.$router.push({
          path: '/messageDetail',
          query: { id: item.id, type: 'journal' },
        });
      } else if (item.contentType == 'LECTURES') {
        this.$router.push({
          path: '/messageDetail',
          query: { id: item.id, type: 'lecture' },
        });
      } else if (item.contentType == 'NEWS') {
        this.$router.push({
          path: '/messageDetail',
          query: { id: item.id, type: 'message' },
        });
      } else if (item.contentType == 'ARTICLE') {
        this.$router.push({
          path: '/messageDetail',
          query: { id: item.id, type: 'article' },
        });
      } else if (item.contentType == 'BOOK') {
        this.$router.push({
          path: '/messageDetail',
          query: { id: item.id, type: 'book' },
        });
      } else if (item.contentType == 'ANNUAL_MEETING') {
        this.$router.push({
          path: '/messageDetail',
          query: { id: item.id, type: 'meeting' },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
// TODO:
@media (min-width: 768px) {
  .highlight {
    color: red !important;
  }
  /deep/ .el-input-group {
    width: 30%;
  }
  .container {
    width: 100vw;
    overflow-x: hidden;
    .header {
      margin: 30px 50px;
      margin-top: 30px;
      width: 100vw;
    }
    .body {
      .bodyHeader {
        margin: 0 50px;
        font-weight: 600;
        font-size: 30px;
        text-align: left;
        display: flex;
        // justify-content: center;
        align-items: center;
        color: #030303;

        .bodyTitle {
          width: 300px;
        }
        .spliceLine {
          height: 2px;
          width: calc(100vw - 20px);
          background-color: #cdcdcd;
        }
      }
      .results {
        .result {
          padding-left: 27%;
          text-align: left;
          margin: 50px 50px;
          cursor: pointer;
          .title {
            display: flex;
            align-items: center;
            .type {
              text-align: center;
              line-height: 20px;
              min-width: 50px;
              width: auto;
              height: 20px;
              color: #fff;
              background-color: #82111f;
              letter-spacing: 2px;
            }
            .messageTitle {
              margin-left: 20px;
              font-size: 26px;
              font-weight: bold;
              color: #355bb6;
            }
          }
          .time {
            margin-top: 20px;
            color: #bbbbbb;
            font-size: 20px;
            letter-spacing: 1px;
          }
          .abstract {
            margin-top: 10px;
            width: 45vw;
            // height: 30px;
            min-height: 30px;
            font-size: 18px;
            color: #a7a7a7;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .keywords {
            display: flex;
          }
          .keyword:hover {
            color: red;
          }
          .keyword {
            margin-top: 10px;
            margin-right: 10px;
            color: #414089;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .highlight {
    color: red !important;
  }
  .container {
    .header {
      margin: 30px 50px;
      margin-top: 30px;
      width: 70vw;
    }
    .body {
      .bodyHeader {
        margin: 0 50px;
        font-weight: 600;
        font-size: 30px;
        text-align: left;
        display: flex;
        // justify-content: center;
        align-items: center;
        color: #030303;

        .bodyTitle {
          width: 80vw;
        }
        .spliceLine {
          height: 2px;
          width: calc(100vw - 20px);
          background-color: #cdcdcd;
        }
      }
      .results {
        .result {
          text-align: center;
          margin: 50px 50px;
          cursor: pointer;
          .title {
            display: flex;
            // justify-content: center;
            align-items: center;
            .type {
              text-align: center;
              line-height: 20px;
              min-width: 50px;
              width: auto;
              height: 20px;
              color: #fff;
              background-color: #82111f;
              letter-spacing: 2px;
            }
            .messageTitle {
              margin-left: 20px;
              font-size: 26px;
              font-weight: bold;
              color: #355bb6;
            }
          }
          .time {
            margin-top: 20px;
            color: #bbbbbb;
            font-size: 20px;
            letter-spacing: 1px;
          }
          .abstract {
            margin-top: 10px;
            width: 45vw;
            // height: 30px;
            min-height: 30px;
            font-size: 18px;
            color: #a7a7a7;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .keywords {
            display: flex;
          }
          .keyword:hover {
            color: red;
          }
          .keyword {
            margin-top: 10px;
            margin-right: 10px;
            color: #414089;
          }
        }
      }
    }
  }
}
</style>
