<template>
  <div class="container">
    <div class="main">
      <div class="header">用户登录与注册</div>
      <div class="content">
        <el-input
          placeholder="请输入邮箱账号"
          v-model="form.username"
          clearable
          class="username"
        >
        </el-input>
        <el-input
          placeholder="请输入密码"
          v-model="form.password"
          clearable
          class="password"
          type="password"
        >
        </el-input>
      </div>
      <div class="forgetPassword" @click="pushToForgetPassword">忘记密码</div>
      <!-- 验证码 -->
      <div class="code">
        <el-input
          placeholder="验证码"
          v-model="form.code"
          clearable
          class="password"
        >
        </el-input>
        <div class="pic" v-loading="loading">
          <!-- 点击之后 刷新code -->
          <img :src="base64ImgUrl" alt="" @click="getCode" />
        </div>
      </div>
      <div class="btn">
        <el-button type="primary" class="login" @click="login">登录</el-button>
        <el-button type="primary" class="register" @click="pushToRegister"
          >注册</el-button
        >
      </div>
      <div class="authorLoginOrRegister">
        <el-button
          type="primary"
          class="authorLogin"
          @click="pushIntoAuthorLogin"
          >作者登录与注册</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
        code: "",
        uuid: "",
      },
      base64ImgUrl: "",
      // 控制验证码loading的显示与隐藏
      loading: false,
    };
  },
  created() {},
  mounted() {
    // 获取验证码
    this.getCode();
  },
  methods: {
    async getCurrentUserMessageToLocalStorage() {
      const { data: res } = await this.$http.get("/user/profile/get");
      // 只要不是默认头像 就要对获取的头像进行完善端口
      if (res.data.user.avatar != "") {
        // 登录的时候 localStorage 默认为空此时在加入时 要先对 avatar 进行修改
        const frontendIP = window.location.hostname;
        // 获取前端的端口地址
        let frontendPort = window.location.port;
        let avatar =
          "http://" +
          frontendIP +
          ":" +
          frontendPort +
          process.env.VUE_APP_BASE_API +
          res.data.user.avatar;
        res.data.user.avatar = avatar;
      }
      this.$emit("alreadyLogin", res.data.user.nickName);
      localStorage.setItem("userInfo", JSON.stringify(res.data.user));
      this.$router.push("/home");
    },
    async login() {
      const { data: res } = await this.$http.post("/login", {
        ...this.form,
        userType: 0, //  用户类型 0普通用户，1作者用户 (不能为空)
      });
      if (res.code == "SUCCESS") {
        // 登录成功
        localStorage.setItem(
          "user",
          JSON.stringify({
            isLogin: true,
            token: res.data.token,
            username: this.form.username,
          })
        );
        this.getCurrentUserMessageToLocalStorage();
        this.$message.success("登录成功");
      } else {
        this.$message.warning(res.message);
        this.getCode();
      }
    },
    //base64转图片
    base64ImgToFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    // 获取验证码
    async getCode() {
      this.loading = true;
      const { data: res } = await this.$http.get("/captcha/captchaImage");
      this.loading = false;
      this.form.uuid = res.data.uuid;
      let tmp = "data:image/png;base64," + res.data.img;
      const imgFile = this.base64ImgToFile(tmp);
      this.base64ImgUrl = URL.createObjectURL(imgFile);
    },
    pushToRegister() {
      this.$router.push({ path: "/register", query: { type: "member" } });
    },
    // 跳转到作者登录页面
    pushIntoAuthorLogin() {
      this.$router.push({ path: "/authorLogin" });
    },
    // 跳转到忘记密码页面
    pushToForgetPassword() {
      this.$router.push({ path: "/forgetPassword", query: { type: "member" } });
    },
  },
};
</script>
<style lang="less" scoped>
// 消去不对齐的验证码
/deep/ .el-input__suffix {
  display: none;
}
.container {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  .main {
    margin-top: 80px;
    // background-color: pink;
    width: 600px;
    height: auto;
    box-shadow: 5px 5px 5px 0px rgba(245, 245, 245, 0.8),
      -5px -5px 5px 0px rgba(245, 245, 245, 0.8),
      5px -5px 5px 0px rgba(245, 245, 245, 0.8),
      -5px 5px 5px 0px rgba(245, 245, 245, 0.8);
  }
  .header {
    background-color: #15242c;
    width: 100%;
    height: 60px;
    font-size: 30px;
    color: #fff;
    line-height: 60px;
    text-align: center;
  }
  .content {
    padding: 50px 80px;
    padding-bottom: 0;
    /deep/ .el-input--suffix .el-input__inner {
      height: 55px;
      border-radius: 10px;
      font-size: 20px;
      color: #bcbcbe;
      background-color: #f4f6fa;
    }
    .username {
      height: 75px;
      margin-bottom: 30px;
    }
  }
  .forgetPassword {
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 80px;
    margin-top: 20px;
    font-size: 20px;
    color: #bcbcbc;
  }
  .btn {
    text-align: left;
    margin-top: 50px;
    padding: 0 80px;
    margin-bottom: 30px;
    .login {
      width: 180px;
      font-size: 18px;
      margin-right: 70px;
      border-radius: 10px;
      background-color: #15242d;
      border-color: #15242d;
    }
    .register {
      width: 180px;
      font-size: 18px;
      border-radius: 10px;
      background-color: #83111e;
      border-color: #83111e;
    }
  }
  .code {
    display: flex;
    width: 100%;
    padding: 30px 80px;
    padding-bottom: 0;
    text-align: left;
    /deep/ .el-input--suffix .el-input__inner {
      height: 55px;
      width: 80%;
      border-radius: 10px;
      font-size: 20px;
      color: #bcbcbe;
      background-color: #f4f6fa;
    }
    .el-color-picker__icon,
    .el-input,
    .el-textarea {
      width: 53%;
    }
    .pic {
      background-color: pink;
      width: 20%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .authorLogin {
    height: 60px;
    margin-top: 30px;
    background-color: #1c5aa0;
    border-color: #1c5aa0;
    width: 100%;
    font-size: 22px;
    border-radius: 10px;
  }
  .authorLoginOrRegister {
    padding: 0 80px;
    height: 100px;
  }
}
</style>
