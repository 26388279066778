<!--/*
 * @file           journal
 * @description    学会简介
 * @author         zrh
 * @date           2024-01-10
 */ -->
<template>
  <div class="container">
    <div class="main">
      <div class="TopBox">
        <div class="title titleFont">联系支持</div>
        <div class="topMain">
          汉语公共神学学会目前正在美国注册登记成为非营利机构，
          <br />
          并期望可以取得501(c)(3)免税资格。
          <br />
          想要更多了解或支持学会及其事工和项目，请致信
          <span style="color: #303fb9"> info@sfcpt.org</span>
        </div>
      </div>
      <div class="imgBox">
        <img :src="FinallyImage" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      FinallyImage: require("../assets/images/contact.png"),
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.titleFont {
  font-family: "KaiTi", serif;
}
// PC端
@media (min-width: 768px) {
  .container {
    width: 99vw;
    display: flex;
    justify-content: center;
    font-family: "KaiTi", serif;
    .imgBox {
      text-align: right;
      margin-right: 150px;
    }
    .main {
      background-color: #f5f5f5;
      width: 85%;
      height: 100vh;
      display: flex; // 父级的flex 代表子级元素的显示由原来默认的block 变为 inline-block
      flex-direction: column;
      padding-top: 20px;
      .TopBox {
        text-align: left;
        .topMain {
          margin: 80px 150px;
          font-size: 40px;
          font-family: "KaiTi", serif;
        }
      }
      .title {
        position: relative; //
        margin-left: 80px;
        font-size: 40px;
        font-weight: 600;
        text-align: left;
      }
      .title::after {
        position: absolute;
        content: "";
        width: 160px;
        height: 10px;
        left: 0;
        top: 50px;
        background-color: #1c5aa0;
      }
      .positionItems {
        margin-top: 40px;
        padding-left: 100px;
        margin-bottom: 200px;
        .positionItem {
          .position {
            font-size: 40px;
            text-align: left;
            font-weight: 600;
          }
          .people {
            text-align: left;
            margin-top: 70px;
            margin-left: 50px;
            font-size: 50px;
          }
          .leftBox {
            width: 55%;
            .name {
              font-size: 50px;
              margin-bottom: 30px;
              color: #929292;
            }
            .introduceBox {
              font-size: 16px;
              display: flex;
              justify-content: space-between;
              padding-right: 50px;
              margin-bottom: 130px;
              .introduce {
                font-size: 30px;
              }
            }
          }
          .rightBox {
            margin-right: 150px;
            margin-left: 200px;
            img {
              width: 300px;
              height: 300px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  img {
    width: 150px;
    height: 150px;
  }
  .container {
    .main {
      justify-content: center;
      align-items: center;
      width: 100%;
      display: flex; // 父级的flex 代表子级元素的显示由原来默认的block 变为 inline-block
      flex-direction: column;
      padding-top: 20px;
      .title {
        position: relative; //
        // margin-left: 50px;
        font-size: 40px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 30px;
      }
      .title::after {
        position: absolute;
        content: "";
        width: 160px;
        height: 10px;
        left: 0;
        top: 50px;
        background-color: #1c5aa0;
      }
      .positionItems {
        .position {
          font-size: 30px;
          text-align: center;
          font-weight: 600;
          margin-bottom: 20px;
        }
        .people {
          display: flex;
          flex-direction: column;
          .ItemBox {
            display: flex;
            flex-direction: column-reverse;
            .leftBox {
              .name {
                margin-top: 20px;
                font-size: 30px;
                margin-bottom: 20px;
                color: #929292;
              }
              .introduceBox {
                padding: 0 20px;
                text-align: left;
                .introduce {
                  margin-bottom: 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
